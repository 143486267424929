<template>
    <div class="coupon-page">
      <div class="title">优惠券</div>
      <div class="search-div">
        <el-button type="primary" size="small" @click="jumpToAddCoupon()">添加优惠券</el-button>
        <div style="display: flex;">
          <el-input size='medium' v-model="querySelect" style="margin-right: 10px;"></el-input>
          <el-button type="primary" size="small" @click="searchList">搜索</el-button>
        </div>
      </div>
      <div class="table-container">
        <el-table border :data="couponList">
          <el-table-column prop="coupon_name" align="center" label="优惠券名称"></el-table-column>
          <el-table-column align="center" label="类型">
            <template slot-scope="scope">
              {{ scope.row.type === '1' ? '全部可用' : scope.row.type === '2' ? '指定业务可用' : scope.row.type === '3' ? '指定商品可用' : scope.row.type === '4' ? '邀请好友' : '新人折扣' }}
            </template>
          </el-table-column>
          <el-table-column prop="price" align="center" label="金额类型" width="70px">
            <template slot-scope="scope">
              {{ scope.row.coupon_amount_type === 1 ? '满减' : scope.row.coupon_amount_type === 2 ? '折扣' :  '指定金额'}}
            </template>
          </el-table-column>
          <el-table-column prop="price" align="center" label="金额">
            <template slot-scope="scope">
              {{ scope.row.coupon_amount_type === 1 ? '满'+ scope.row.meet_amount/100 + '元减' + scope.row.designated_amount/100 + '元' : scope.row.coupon_amount_type === 2 ? scope.row.discount/100 + '折':  scope.row.designated_amount/100 + '元'}}
            </template>
          </el-table-column>
          <el-table-column align="center" label="有效期" width="60px">
            <template slot-scope="scope">
              <span v-if="scope.row.coupon_date_type === 1">不限时间</span>
              <span v-else>
                <div>{{scope.row.start_date | datefmt('YYYY-MM-DD HH:mm:ss') }}</div>
                至
                <div>{{scope.row.end_date | datefmt('YYYY-MM-DD HH:mm:ss') }}</div>
              </span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="每人限领" width="60px">
            <template slot-scope="scope">
              <span>{{ scope.row.restricted_collection_num }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="已领" width="60px">
            <template slot-scope="scope">
              <span>{{ scope.row.receive_nums }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="更新时间">
            <template slot-scope="scope">
              {{scope.row.update_time | datefmt('YYYY-MM-DD HH:mm:ss') }}
            </template>
          </el-table-column>
          <el-table-column prop="update_user_name" align="center" label="最后更新者" width="100px" >
            <template slot-scope="scope">
              {{scope.row.update_user_name || scope.row.create_user_name}}
            </template>
          </el-table-column>
          <el-table-column prop="update_user_name" align="center" label="启用状态" width="80px">
            <template slot-scope="scope">
              {{ getStatus(scope.row.enable) }}
            </template>
          </el-table-column>
          <el-table-column align="center" width="120px" label="操作">
            <template slot-scope="scope">
              <span>
                <el-button v-if="scope.row.enable === 1" type="text" size="small" style="margin-right: 10px" @click="enableCoupon(scope.row)">启用</el-button>
              </span>
              <span>
                <el-button v-if="scope.row.enable === 2" type="text" size="small" style="margin-right: 10px" @click="UnenableCoupon(scope.row)">禁用</el-button>
              </span>
              <span>
                <el-button :disabled="scope.row.enable === 2" type="text" size="small" style="margin-right: 10px" @click="jumpToAddCoupon(scope.row)">编辑</el-button>
              </span>
              <span>
                <el-popconfirm
                  confirmButtonText='确定'
                  cancelButtonText='不用了'
                  icon="el-icon-info"
                  iconColor="red"
                  @confirm="deleteCoupon(scope.row.coupon_id)"
                  title="确定删除该优惠券吗？">
                  <el-button :disabled="scope.row.enable === 2" slot="reference" type="text" size="small" @click="couponId=scope.row.coupon_id">删除</el-button>
                </el-popconfirm>
              </span>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination class="page-pageination" background layout="prev, pager, next" :total="total" :page-size="size" :current-page.sync="page" @current-change="getCouponList"></el-pagination>
      </div>
    </div>
  </template>

<script>
export default {
    name: 'Coupon',
    data () {
        return {
            couponList: [],
            page: 1,
            size: 10,
            total: 0,
            querySelect: '',
            visible: false,
            couponId: ''
        }
    },
    mounted () {
        this.getCouponList()
    },
    methods: {
        getStatus (status) {
            return status === 1 ? '已禁用' : '已启用'
        },
        enableCoupon (coupon) {
            const couponData = {
                coupon_id: coupon.coupon_id
            }
            console.log('这是优惠券数据', coupon)
            this.axios.post('/coupon/enable', couponData).then((res) => {
                // 处理启用成功后的逻辑
                coupon.enable = 2
                this.$message({
                    message: '启用成功',
                    type: 'success'
                })
                console.log(res)
            }).catch((error) => {
                // 处理启用失败后的逻辑
                console.error(error)
            })
        },
        UnenableCoupon (coupon) {
            const couponData = {
                coupon_id: coupon.coupon_id
            }
            console.log('这是优惠券数据', coupon)
            this.axios.post('/coupon/enable', couponData).then((res) => {
                // 处理禁用成功后的逻辑
                coupon.enable = 1
                this.$message({
                    message: '禁用成功',
                    type: 'success'
                })
                console.log(res)
            }).catch((error) => {
                // 处理禁用失败后的逻辑
                console.error(error)
            })
        },
        getCouponList () {
            this.axios.post('/coupon/list', {
                page: this.page,
                size: this.size,
                search: this.querySelect
            }).then((res) => {
                this.couponList = res.data.list
                this.total = res.data.count
                console.log(res)
            })
        },
        searchList () {
            this.page = 1
            this.getCouponList()
        },
        jumpToAddCoupon (editCouponData) {
            if (editCouponData) {
                localStorage.setItem('couponEditData', JSON.stringify(editCouponData))
            }
            this.$router.push('coupon/add/' + !!editCouponData)
        },
        deleteCoupon (couponId) {
            this.axios.post('/coupon/delete', {
                coupon_id: couponId
            }).then((res) => {
                if (res) {
                    this.getCouponList()
                    this.$message.success('删除成功')
                }
            }).catch((error) => {
                console.error(error)
                this.$message.error('删除失败：' + error.message)
            })
        }
    }
}
</script>

  <style lang="scss">
  .coupon-page{
    .title{
      padding: 14px;
      border-bottom: 1px solid #dddddd;
      font-size: 28px;
    }
    .search-div{
      display: flex;
      justify-content: space-between;
      padding: 10px 20px;
      border-bottom: 1px solid #dddddd;
    }
    .table-container{
      margin: 10px 20px 0;
    }
    .page-pageination{
      padding: 10px 0;
      text-align: right;
    }
  }
  </style>
